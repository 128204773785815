import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import CategoryItem from './CategoryItem'
import { TopCategoriesQuery } from '../../types/graphql-types'

const TopCategories = (): React.ReactElement => {
  const {
    allContentstackCategory: { nodes: categories },
  } = useStaticQuery<TopCategoriesQuery>(query)

  return (
    <div className="section">
      <div className="container">
        <div className="row">
          {categories.map(({ id, title, url, image }) => (
            <div key={id} className="col-md-4 col-xs-6">
              <CategoryItem title={title} url={url} imageUrl={image.url} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const query = graphql`
  query TopCategories {
    allContentstackCategory(limit: 3) {
      nodes {
        id
        title
        url
        image {
          url
        }
      }
    }
  }
`

export default TopCategories
