import React from 'react'
import TopCategories from '../components/TopCategories'
import NewProducts from '../components/NewProducts'
import HotDeal from '../components/HotDeal'
import TopSellingProducts from '../components/TopSellingProducts'

const Index: React.FC = () => {
  return (
    <>
      <TopCategories />
      {/* <NewProducts /> */}
      <HotDeal />
      {/* <Search /> */}
      {/* <TopSellingProducts /> */}
    </>
  )
}

export default Index
