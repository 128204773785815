import React from 'react'
import { Link } from 'gatsby'

type Props = {
  title: string
  url: string
  imageUrl: string
}

const CategoryItem = ({ title, url, imageUrl }: Props): React.ReactElement => {
  return (
    <div className="shop">
      <div className="shop-img">
        <img src={imageUrl} alt="" />
      </div>
      <div className="shop-body">
        <h3>
          {title}
          <br />
          Collection
        </h3>
        <Link to={url} className="cta-btn">
          Shop now <i className="fa fa-arrow-circle-right"></i>
        </Link>
      </div>
    </div>
  )
}

export default CategoryItem
